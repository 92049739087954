<template>
<div>
    <div>
        <vc-header :save="isEditable" close @save="save()" @close="$emit('close')" :approve="optionApproved" :transactionApproved="transactionApproved" :title="'Requerimiento  ' + (requirement.ReqID <= 0 ? '' : requirement.ReqDocumentNumber + ' - ' + requirement.UsrName)
        ">
        </vc-header>
        <v-card class="mt-1 pt-4" style="max-height: 650px;  overflow-y: auto;">
            <form id="requirement">
                <v-row style="margin:auto">
                    <v-col lg="4" cols="12">
                        <v-row style="margin-top: -20px" class="mb-1">
                            <v-col cols="12" class="s-col-form">
                                <vc-text disabled label="Usuario" :value="requirement.ReqID > 0 ? requirement.UsrName : user.NtpFullName"></vc-text>
                            </v-col>
                            <!-- <v-col lg="4" md="4" cols="4" class="s-col-form">
                                <vc-text id="ReqDayExpiration" :disabled="!isEditable" number :min="1" label="Tiempo Duración" v-model="requirement.ReqDayExpiration" ref="ReqDayExpiration"></vc-text>
                            </v-col> -->
                            <v-col cols="9" class="s-col-form">
                                <vc-select-definition autocomplete full id="TypeReason" :def="1054" label="Motivo del Req." :disabled="!isEditable" v-model="requirement.TypeReason"></vc-select-definition>
                            </v-col>
                            <v-col cols="3" class="s-col-form pt-0" v-if="value > 0">
                                <!-- <v-btn small elevation="0" color="success" class="capitalize" outlined><i class="fa-solid fa-layer-group"></i>Distribuir </v-btn> -->
                                <v-btn text outlined elevation="0" class="capitalize mt-5" small @click="showUpload=true">
                                    <v-icon color="green" style="font-size:16px;margin-right:5px;">fa-solid fa-file</v-icon>Anexos
                                    <!-- <i class="fa-solid fa-file"></i> -->
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col lg="8" cols="12">
                        <v-row justify="center" style="border-left-style: outset; margin-top: -20px">
                            <v-col cols="6" lg="4" class="s-col-form">
                                <vc-text id="AreID" label="Área" disabled v-model="AreName"></vc-text>
                            </v-col>
                            <v-col cols="6" lg="4" class="s-col-form">
                                <vc-date id="ReqDate" label="Fecha Solicitud" :value="requirement.ReqDate" disabled v-model="requirement.ReqDate" />
                            </v-col>
                            <v-col cols="6" lg="4" class="s-col-form">
                                <vc-date id="NeedDate" label="Fecha Atención" :disabled="!isEditable" :value="requirement.NeedDate" v-model="requirement.NeedDate" />
                            </v-col>
                            <v-col cols="6" lg="4" class="s-col-form">
                                <vc-date id="NeedDateCot" label="Fecha de Necesidad Cot." :disabled="!isEditable" :value="requirement.NeedDateCot" v-model="requirement.NeedDateCot" />
                            </v-col>
                            <!--  <v-col cols="6" lg="4" class="s-col-form">
                                <vc-select-definition id="TypeEar" :def="1012" v-model="requirement.TypeEar" label="Tipo" :disabled="!isEditable"></vc-select-definition>
                            </v-col> -->
                            <v-col cols="4" lg="2" class="s-col-form" v-if="!inbox">
                                <vc-select-definition id="ReqStatus" :dgr="1001" :def="1009" label="Estado" :disabled="!isEditable" v-model="requirement.ReqStatus" />
                            </v-col>
                            <v-col cols="4" lg="2" class="s-col-form" v-if="!isEditable" >
                                <vc-select-definition clearable noDefault :def="1012" label="Categoria" v-model="requirement.TypeCategory" :disabled="!isEditable && !inbox" />
                                <!-- <v-btn x-small text style="text">Grabar</v-btn> -->
                                <a style="text-decoration:underline;color:blue" v-if="inbox" @click="editCategory">Grabar</a>
                            </v-col>
                            <v-col cols="4" lg="2" class="s-col-form">
                                <vc-select-definition id="asignar" :def="1090" v-model="requirement.TypeCategory" label="Asignar a"></vc-select-definition>
                                <a style="text-decoration:underline;color:blue"  @click="asignar">Grabar</a>
                            </v-col>
                            <v-col cols="6" lg="4" class="s-col-form">
                                <vc-select-definition id="TypePriority" :def="1008" label="Prioridad de Atención" :disabled="!isEditable" v-model="requirement.TypePriority">
                                </vc-select-definition>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col cols="12">
                        <v-card outlined elevation="0" height="auto" v-if="inbox">
                            <v-row style="margin-top: -20px" class="mb-1">
                                <v-col cols="4" class="s-col-form">
                                    <vc-select-definition :def="0" label="Categorizar" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row> -->
                <!-- <v-divider class="mt-0 mb-0" /> -->
                <v-row style="margin:auto">
                    <v-col lg="4" cols="12" v-if="isEditable">
                        <v-card outlined elevation="0" height="auto" v-if="isEditable">
                            <vc-header-title class="mb-2 pt-0 pb-0" :title="isEditable ? 'Nuevo Item' : ''" :add="isEditable" @add="add()" @clear="clearForm()" :clear="isEditable" />
                            <v-row style="margin: auto">
                                <v-col style="margin-top: -10px" class="pb-6">
                                    <v-row justify="center">
                                        <v-col class="s-col-form" cols="39">
                                            <vc-article-search :readonly="editItem" v-model="article" :Inventoriable="typeRequirement" />
                                        </v-col>
                                        <v-col class="s-col-form" cols="3">
                                            <vc-text label="Cantidad" decimal ref="DerQuantity" v-model="item.DerQuantity" />
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" class="s-col-form pt-0">
                                            <vc-textarea label="Detalles" v-model="item.DerObservation" /><span style="font-size: 10px; color: red">{{ this.item.DerObservation.length }} Caracteres</span>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" class="s-col-form pt-0">
                                            <v-checkbox hide-details label="Aplica distribución para todos" v-model="apply"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" class="s-col-form pt-0">
                                            <!-- <v-btn small elevation="0" color="success" class="capitalize" outlined><i class="fa-solid fa-layer-group"></i>Distribuir </v-btn> -->
                                            <v-btn elevation="0" class="capitalize mt-2" small @click="openDetail()">
                                                <v-icon color="green" style="font-size:16px;margin-right:5px;">fa-solid fa-layer-group</v-icon>Distribuir
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <v-col :lg="!isEditable ? '12' : '8'" cols="12">
                        <v-row class="mb-3" style="border-left-style: outset">
                            <v-col cols="12" class="text-center" style="margin-bottom:0px;margin-top:-10px" v-if="itemsContract.length > 0">
                                <h6>Este Requerimiento esta vinculado a una solicitud de contrato.
                                    <v-btn v-if="value > 0" x-small outlined class="ml-2" @click="viewContract=true">Ver Anexos</v-btn>
                                    <!--  <v-btn v-if="value > 0" x-small outlined class="ml-2" @click="viewContract=true">
                                        <v-icon color="green">mdi-download</v-icon>Descargar Doc. Firmado
                                    </v-btn> -->
                                    <h5 v-if="value > 0 && requirement.ExistContractFinal !== null"><a @click="downloadFile">
                                            <v-icon color="green">mdi-download</v-icon>Descargar Contrato Firmado
                                        </a></h5>
                                    <h5 v-if="value > 0 && requirement.ExistContractFinal == null">Contrato pendiente de firma</h5>
                                </h6>
                            </v-col>
                            <v-col cols="12">
                                <vc-header-title title="Detalle" style="margin-top: -20px" class="mb-2 pt-2 pb-0" :edit="isEditable" :remove="isEditable" @edit="edit()" @delete="removeItem()" :importfile="isEditable" />
                                <v-card outlined class="elevation-0">
                                    <v-col style="padding: 0px">
                                        <v-data-table class="elevation-0 table-hover table-condensed table-bordered" item-key="DerNumLine" disable-sort dense :items="items" :height="!isEditable ? '180' : $vuetify.breakpoint.xs ? '180' : '180'" :headers="headers" v-model="selected" hide-default-footer :items-per-page="-1" @click:row="rowClick">
                                            <template v-slot:item.View="{ item }">
                                                <v-icon @click="dialogDetailEditable(item)" v-if="!isEditable" style="font-size:18px;color:green">
                                                    far fa-eye
                                                </v-icon>
                                                <v-icon v-if="(item.Details.length > 0) && isEditable" color="green darken-3" style="font-size: 12px"> fa-solid fa-check-double</v-icon>
                                                <v-icon v-if="(item.Details.length == 0) && isEditable" color="orange darken-3" style="font-size: 12px"> fas fa-exclamation</v-icon>
                                            </template>
                                        </v-data-table>

                                    </v-col>
                                </v-card>
                            </v-col>
                            <v-col lg="12" class="pt-6" v-if="transactionApproved != null">
                                <h5 v-if="transactionApproved.AtsStatus == 3" style="color: var(--v-error-base)" class="text-center">
                                    Desaprobado:
                                    {{ transactionApproved.AtsObservation }}
                                </h5>
                                <h5 v-if="transactionApproved.AtsStatus == 2" style="color: var(--v-success-base)" class="text-center">
                                    Aprobado
                                </h5>
                            </v-col>
                            <v-col cols="12" class="s-col-form">
                                <quill-editor id="ReqObservation" v-model="requirement.ReqObservation" ref="myQuillEditor" :options="editorOption"></quill-editor>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </form>
        </v-card>
        <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />
        <vc-modal-circuit close :circuits="circuits" :TrsCode="TrsCode" :dialog="dialogCircuit" @close="dialogCircuit = false" :width="circuits.length > 1 ? 940 : 500" />
        <vc-footer style="box-shadow: -2px 15px 15px 11px"></vc-footer>
        <v-dialog v-model="showUpload" v-if="showUpload" max-width="800">
            <vc-upload-files :config="configFile" @close="showUpload=false;" />
        </v-dialog>
        <v-dialog v-model="dialogDetail" v-if="dialogDetail" width="70%">
            <vc-header :title="'Distribución'" close @close="dialogDetail=false;">
                <template v-slot:options>
                    <!-- <div class="mt-0 mr-3">
                        <label><b>Seleccione Empresa:</b></label>
                    </div> -->
                    <!-- <div class="mt-1 mr-3">
                    </div> -->
                </template>
            </vc-header>
            <v-card outlined>
                <div class="mt-0 ml-3">
                    <v-row>
                        <v-col cols="4">
                            <vc-select-definition label="Seleccione Empresa" @input="inputBussines($event)" :def="1059" return-object v-model="objBussiness">
                            </vc-select-definition>
                        </v-col>
                    </v-row>
                    <v-radio-group dense v-model="TypeP" style="margin-top:0px;margin-bottom:-12px" :disabled="!isEditable">
                        <v-radio value="1">
                            <template v-slot:label>
                                <div>-<strong class="error--text">Gasto</strong></div>
                            </template>
                        </v-radio>
                        <v-radio value="2" style="margin-top: -10px">
                            <template v-slot:label>
                                <div>-<strong class="primary--text">Proyecto</strong></div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </v-card>

            <vc-header :title="TypeP == 1 ? 'PART.GASTO - PART.COSTO' : 'PROYECTO - PARTIDAS'" :acceptAdd="true" :add="isEditable" @add="addDistribution()" :clear="isEditable" @clear="clearFormDistribution()" :remove="isEditable" @delete="removeDistribution()"></vc-header>
            <v-card outlined>
                <v-row style="margin:auto" v-if="isEditable &&  TypeP == 1">
                    <v-col lg="12" class="s-col-form" cols="12">
                        <vc-projects clearable noDefault full label="Proyectos" return-object v-model="itemDistribution.ObjPrj" :business="typeBusinessDistribution"></vc-projects>
                    </v-col>
                    <v-col lg="5" md="5" cols="5" style="padding-top: 0px; padding-bottom: 0px" class="mt-3">
                        <vc-type-spent clearable noDefault full label="Part. Gasto" return-object v-model="itemDistribution.ObjPtd_GF" :areID="requirement.AreID"></vc-type-spent>
                    </v-col>
                    <v-col lg="5" md="5" cols="5" style="padding-top: 0px; padding-bottom: 0px" class="mt-3">
                        <vc-center-coste clearable noDefault full label="Part. Costo" return-object v-model="itemDistribution.ObjPrj_Ceco" :business="typeBusinessDistribution" :areID="requirement.AreID"></vc-center-coste>
                    </v-col>
                    <v-col lg="2" md="2" class="" cols="2">
                        <vc-text label="%" decimal ref="DepPorcentaje" v-model="itemDistribution.DepPorcentaje" :disabled="!isEditable" />
                    </v-col>
                    <v-col cols="12">
                        <vc-type-spent-detail clearable noDefault full :disabled="!isEditable" v-if="itemDistribution.ObjPtd_GF != 0 && itemDistribution.ObjPrj_Ceco != 0" :gf="itemDistribution.ObjPtd_GF.SptID" :ceco="itemDistribution.ObjPrj_Ceco.CceCenterCoste" :areID="requirement.AreID" v-model="itemDistribution.account" />
                    </v-col>
                </v-row>
                <v-row style="margin:auto" v-if="isEditable && TypeP == 2">
                    <v-col lg="12" class="s-col-form" cols="12">
                        <vc-projects clearable noDefault full label="Proyectos" return-object v-model="itemDistribution.ObjPrj_Ceco" :business="typeBusinessDistribution"></vc-projects>
                    </v-col>
                    <v-col lg="8" class="" cols="6">
                        <vc-partida label="Partida" full clearable noDefault return-object v-model="itemDistribution.ObjPtd_GF" :business="typeBusinessDistribution"></vc-partida>
                    </v-col>
                    <v-col lg="4" class="" cols="6">
                        <vc-text label="%" decimal ref="DepPorcentaje" v-model="itemDistribution.DepPorcentaje" />
                    </v-col>
                </v-row>
                <v-row style="margin:auto">
                    <v-col lg="12" class="" cols="12">
                        <v-data-table item-key="DepLine" dense :items="itemsDistribution" :height="'auto'" :headers="headersDistribution" v-model="selectedDistribution" hide-default-footer :items-per-page="-1" disable-sort @click:row="rowClickDistribution">
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="viewContract" v-if="viewContract" max-width="80%">
            <lgs-contract-legal-view @close="viewContract=false" :itemsContract="itemsContract" />
        </v-dialog>
    </div>
</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Logistics/LgsRequirementService";
import _sRequirementDetail from "@/services/Logistics/LgsRequirementDetailService";

//Components
import vcTypeSpent from '@/components/Logistics/vcTypeSpent.vue';
import vcTypeSpentDetail from '@/components/Logistics/vcTypeSpentDetail.vue';
import vcProjects from '@/components/Logistics/vcProjects.vue';
import vcPartida from '@/components/Logistics/vcPartida.vue';
import vcCenterCoste from '@/components/Logistics/vcCenterCoste.vue';
import LgsContractLegalView from '../LgsContractLegalView.vue';

import _sWorkerService from "@/services/General/GenWorkerService";
import _sSecMailService from "@/services/Security/SecMailService";

//Functions
import _validaERP from "@/plugins/validaERP";
import _sHelper from "@/services/HelperService.js";

import {
    quillEditor
} from "vue-quill-editor";

export default {
    components: {
        quillEditor,
        vcTypeSpent,
        vcTypeSpentDetail,
        vcProjects,
        vcPartida,
        vcCenterCoste,
        LgsContractLegalView
    },
    props: {
        value: {
            default: 0,
            type: Number,
        },
        typeRequirement: {
            type: Number,
            default: null
        },
        reqExistsCircuit: {
            type: Number,
            default: null
        },
        inbox: {
            type: Boolean,
            default: false
        },
        derId: { // Recibiendo el nuevo valor
            default: 0,
            type: Number,
        }
    },

    data() {
        return {
            //Editor de texto
            editorOption: {
                // some quill options
            },
            //Loading
            processing: false,
            errors: null,

            editItem: false,
            //TypeRequirement: this.typeRequirement,

            requirement: {
                TypeRequirement: this.typeRequirement,
                AreID: 0,
                ReqDate: null,
                UsrID: null,
                ReqID: 0,
                ReqObservation: "",
                ReqStatus: 0,
                ReqDayExpiration: 1,
                ReqReason: "",
            },

            //Detalle
            selected: [],
            items: [],
            itemsRemoved: [],

            item: {
                DerNumLine: 0,
                DerDescription: "",
                DerObservation: "",
                DerQuantity: "",
            },

            headers: [{
                    text: "Distrib.",
                    value: "View",
                    width: 10,
                    align: "center"
                },
                {
                    text: "Descripción",
                    value: "DerDescription",
                    width: 500,
                },
                {
                    text: "Und",
                    value: "DerUnitMeasurement",
                    sortable: false,
                    width: 70,
                },
                {
                    text: "Cant",
                    value: "DerQuantity",
                    sortable: false,
                    width: 70,
                },
                {
                    text: "Comentario",
                    value: "DerObservation",
                    sortable: false,
                    width: 300,
                },
                {
                    text: "Iniciado por",
                    value: "UsrNameInitialProcess",
                    sortable: false,
                    width: 300,
                },
                {
                    text: "Fec. Inicio",
                    value: "DerDateInitialProcess",
                    sortable: false,
                    width: 300,
                },
            ],

            //Files
            showUpload: false,

            //Transacciones
            optionApproved: false,
            transactionApproved: null,

            //Articles
            article: null,

            //Global
            itemsAttach: [],
            AreName: "",

            //Circuitos
            TrsCode: "TrsRequirement",
            circuits: [],
            dialogCircuit: false,
            //
            dialogDetail: false,
            apply: false,
            typeBusinessDistribution: 0,
            objBussiness: null,
            Type: 1,
            TypeP: '1',

            itemDistribution: {
                DepLine: 0,
                ObjPrj: 0,
                ObjPrj_Ceco: 0,
                ObjPtd_GF: 0,
                DepPorcentaje: 100
            },
            itemsDistribution: [],
            selectedDistribution: [],
            headersDistribution: [{
                    text: "Empresa",
                    value: "TypeBusinessName",
                    sortable: false,
                    width: 100
                },
                {
                    text: "Proyecto Gasto",
                    value: "PrjName"
                },
                {
                    text: "Prj_Ceco",
                    value: "Prj_Ceco_Name"
                },
                {
                    text: "Ptd_GF",
                    value: "Ptd_GF_Name"
                },
                {
                    text: "Key - C. Contable",
                    value: "DepAccount"
                },
                {
                    text: "%",
                    value: "DepPorcentaje"
                }
            ],
            configFile: {
                TrsCode: "TrsRequirement",
                DocEntry: this.value,
                ReqID: "ID",
                service: _sRequirement
            },

            //Contratos Legal
            itemsContract: [],
            viewContract: false,

            mail: {},
            nroReq:"",
            userReq:"",
            DerID:""
        };
    },

    created() {

        if (this.value > 0) {
            this.initialize();
        } else {
            this.AreName = this.user.GenWorkerCE.AreName
            this.requirement.AreID = this.user.GenWorkerCE.AreID
        }
        /* console.log(this.user.GenWorkerCE); */
    },

    computed: {
        user() {
            return this.$fun.getUserInfo();
        },
        isEditable() {
            return this.requirement.ReqID == 0 || this.requirement.ReqStatus == 4 && this.reqExistsCircuit == 0;
        },
    },
    mounted() {
        /* if (this.value > 0) {
            this.processing = true;
        } */
    },

    watch: {
        article() {
            this.$nextTick().then(() => {
                if (this.article) {
                    this.$refs.DerQuantity.focus();
                    this.item.DerDescription = this.article.ArtDescription;
                    console.log("article", this.article);
                }
            });
        },
        'requirement.TypeCategory'() {
            /*  this.editCategory() */
        },
        isEditable() {
            this.processing = false;
        },
        TypeP() {
            this.clearFormDistribution()
        },
    },

    methods: {
        initialize() {
            _sRequirement.byID({ReqID: this.value,},this.$fun.getUserID()).then((r) => {
                    this.requirement = r.data.Result;
                    this.itemsContract = r.data.Result.listContract

                    this.items = this.requirement.itemsDetails
                    this.itemsAttach = this.requirement.itemsAttachs
                    this.AreName = this.requirement.AreName

                    console.log("al levantar", this.requirement);
                    this.nroReq = this.requirement.ReqDocumentNumber
                    this.userReq = this.requirement.UsrName

                    this.transactionApproved = this.requirement.Transaction;
                    if (this.requirement.Transaction != null)
                        if (this.requirement.Transaction.UsersApproved.length > 0)
                            if (this.requirement.Transaction.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                                if (this.requirement.ReqStatus == 4) this.optionApproved = true;
                });
        },

        rowClick: function (item, row) {
            this.selected = [item];
        },

        //Detalle

        //Limpiar
        clearForm() {
            this.editItem = false;
            this.item.DerDescription = "";
            this.item.DerObservation = "";
            this.item.DerQuantity = "";
            this.item.DerNumLine = 0;
            this.article = null;
            this.itemsDistribution = []
        },

        //Agregar Item
        add() {
            if (
                this.items.find((x) => x.DerDescription == this.item.DerDescription) &&
                this.item.DerNumLine == 0
            ) {
                this.$fun.alert("Descripción ya fue agregada", "warning");
                return;
            }
            if (this.item.DerQuantity <= 0 || this.item.DerQuantity.toString().trim() == "") {
                this.$fun.alert("Cantidad debe ser mayor a Cero", "warning");
                this.$refs.DerQuantity.focus();
                return;
            }
            if (this.item.DerNumLine == 0) {
                let item = {
                    DerDescription: this.item.DerDescription.toUpperCase(),
                    DerObservation: this.item.DerObservation,
                };
                item.Details = this.itemsDistribution
                if (this.itemsDistribution.length == 0 && this.apply == false) {
                    this.$fun.alert("[IMP] Necesita agregar distribución para item.", "warning")
                    return
                }
                if (this.itemsDistribution.length == 0 && this.apply == true && this.items.length == 0) {
                    this.$fun.alert("A seleccionado aplicar la distribución para todos los Items, al menos el primer item debe tener una distribución.", "warning")
                    return
                }
                if (this.article == null) {
                    this.$fun.alert("Especifique Articulo", "warning");
                    return;
                }
                item.ArtCode = this.article.ArtCode;
                item.DerDescription = this.article.ArtDescription;
                item.DerQuantity = this.item.DerQuantity;
                item.DerUnitMeasurement = this.article.TypeUnitOfMeasuarementName;
                this.items.push(item);
                this.selected = [item];

            } else {
                if (this.itemsDistribution.length == 0 && this.apply == false) {
                    this.$fun.alert("[IMP] Necesita agregar distriubución para item.", "warning")
                    return
                }
                this.items[this.item.DerNumLine - 1].DerDescription = this.item.DerDescription;
                this.items[this.item.DerNumLine - 1].DerObservation = this.item.DerObservation;
                this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
                this.items[this.item.DerNumLine - 1].ArtCode = this.article.ArtCode;
                this.items[this.item.DerNumLine - 1].DerDescription = this.article.ArtDescription;
                this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
                this.items[this.item.DerNumLine - 1].DerUnitMeasurement = this.article.TypeUnitOfMeasuarementName;
                this.selected = [this.items[this.item.DerNumLine - 1]];
                this.items[this.item.DerNumLine - 1].Details = this.itemsDistribution
            }

            for (let i = 0; i < this.items.length; i++) this.items[i].DerNumLine = i + 1;
            this.clearForm();
            this.itemsDistribution = []
            this.clearFormDistribution()
        },

        //editar item
        edit() {
            if (this.selected.length > 0) {
                this.editItem = true;
                let row = this.selected[0];
                this.$nextTick().then((r) => {});
                this.item.DerDescription = row.DerDescription;
                this.item.DerObservation = row.DerObservation;
                this.item.DerNumLine = row.DerNumLine;
                this.item.DerID = row.DerID;
                this.article = {
                    ArtCode: row.ArtCode,
                    ArtDescription: row.DerDescription,
                    TypeUnitOfMeasuarementName: row.DerUnitMeasurement,
                };
                this.item.DerQuantity = row.DerQuantity;
                this.itemsDistribution = row.Details
            }
        },

        //Remover item
        removeItem() {
            if (this.items.length > 0) {
                if (this.selected[0].ReqID > 0) {
                    this.selected[0].SecStatus = 0;
                    this.itemsRemoved.push(this.selected[0]);
                }
                this.items = this.items.filter((x) => x != this.selected[0]);
                this.selected = this.items.length > 0 ? [this.items[0]] : [];
                this.clearForm();
            }
        },

        //Global
        save() {

            var resp = _validaERP.valida({
                form: "#requirement",
                items: {
                    /* AreID: {
                        required: true,
                        value: this.requirement.AreID,
                    }, */
                    ReqDate: {
                        required: true,
                        value: this.requirement.ReqDate,
                    },
                    ReqStatus: {
                        required: true,
                        value: this.requirement.ReqStatus,
                    },
                    TypePriority: {
                        required: true,
                        value: this.requirement.TypePriority,
                    },
                    /* ReqDayExpiration: {
                        required: true,
                        value: this.requirement.ReqDayExpiration,
                    }, */
                    /* TypeEar: {
                        required: true,
                        value: this.requirement.TypeEar,
                    }, */
                    TypeReason: {
                        required: true,
                        value: this.requirement.TypeReason,
                    },
                },
            });

            if (resp) {
                if (this.items.length < 1) {
                    this.$fun.sweetAlert("No se ha registrado ningún detalle", "warning");
                    return;
                }
                if (this.$fun.formatDateView(this.requirement.NeedDate) < this.$fun.formatDateView(this.requirement.ReqDate)) {
                    this.$fun.sweetAlert("Fecha necesidad no puede ser inferior a fecha solicitud.", "warning")
                    return
                }
                this.requirement.AreID = this.user.GenWorkerCE.AreID
                this.requirement.CpyID = this.$fun.getCompanyID()
                this.requirement.UsrID = this.$fun.getUserID();
                this.requirement.SecStatus = 1;
                this.requirement.UsrCreateID = this.$fun.getUserID();
                //this.requirement.itemsAttachs = this.itemsAttach;
                this.requirement.itemsDetails = [...this.items, ...this.itemsRemoved];

                if (this.requirement.itemsDetails.length > 0) {
                    this.requirement.itemsDetails.forEach(element => {

                        if (element.Details.length == 0) {
                            element.Details = this.requirement.itemsDetails[0].Details
                        }
                    });
                }
                console.log("save", this.requirement);
                this.$fun.sweetAlert("¿Seguro de grabar?", "question").then(val => {
                    if (val.value) {
                        this.processing = true
                        _sRequirement.save(this.requirement).then((resp) => {
                                    if (resp.status == 200) {
                                        if (resp.data.Result.Data.length > 0) {
                                            this.circuits = resp.data.Result.Data;
                                            this.dialogCircuit = true;
                                        } else {
                                            this.processing = false;
                                            this.$fun.sweetAlert("Guardado Correctamente", "success");
                                            this.$emit("close");
                                        }
                                    }
                                },
                                (e) => {
                                    this.errors = e.response
                                    this.processing = true;
                                })
                    }
                })
            }
        },

        editCategory() {
            _sRequirement.editCategory({
                ReqID: this.value,
                TypeCategory: this.requirement.TypeCategory,
                UsrUpdateID: this.$fun.getUserID()
            }).then(resp => {
                if (resp.status == 200) {
                    this.$fun.sweetAlert("Actualizado correctamente.", "success")
                    this.$emit('close')
                    /* if(this.requirement.TypeCategory !== null){
                    } */
                }
            })
        },

        async asignar() {
            const resp = await _sWorkerService.usuarioslogistica({              
                });
                if (resp.status == 200) {                    
                    for(let item of resp.data.Result){
                        if(item.WrkID == this.requirement.TypeCategory){
                            _sRequirementDetail.editAsignar({
                                ReqID: this.value,
                                DerID: this.derId,
                                AsignadoA: item.NtpFullName,                           
                                UsrUpdateID: this.$fun.getUserID()
                                }).then(resp => {
                                    if (resp.status == 200) {
                                        this.processing = false
                                        this.$fun.sweetAlert("Actualizado correctamente.", "success")
                                        // this.dialogEdit = false
                                        this.refresh()
                                    }
                                },
                                (e) => {
                                    this.errors = e.response
                                    this.processing = true;
                                })
                            this.mail.CpyID = "1000"
                            this.mail.Email = item.WrkEmail + ";" + "logistica.imp@imperu.com.pe"
                            // this.mail.Email = "carlos.viera@imperu.com.pe" + ";" + "carlos.viera@imperu.com.pe"
                            this.mail.Subject = `Requerimiendo Asignado N°${this.nroReq}`
                            this.mail.Body = `
                                <div style="font-family: Arial, sans-serif; line-height: 1.5; color: #333;">
                                    <h2 style="color: #0056b3;">Requerimiento N°${this.nroReq} - ${this.userReq}</h2>
                                    <p>Hola <strong>${item.NtpFullName}</strong>,</p>
                                    <p>Tienes un nuevo requerimiento asignado. Por favor, revisa los detalles en el sistema:</p>
                                    <p>
                                        <a href="https://appv2.imperu.com.pe/logistica/ordenpedido/atencioncompra" 
                                        style="color: #0056b3; text-decoration: none; font-weight: bold;">
                                        Haz clic aquí para acceder al sistema
                                        </a>
                                    </p>
                                    <footer style="margin-top: 20px; font-size: 0.9em; color: #666;">
                                        <p>Atentamente,</p>
                                        <p>Equipo de Soporte</p>
                                    </footer>
                                </div>
                            `;
                            _sSecMailService.sendMail(this.mail).then(resp => {                                
                                this.processing = false
                                this.$emit('close')
                            },
                            (e) => {
                                this.errors = e.response
                                this.processing = true;
                            })
                        }
                    }
                }            
        },

        inputBussines(item) {
            if (item !== null) {
                this.typeBusinessDistribution = item.DedValue
            }
        },

        clearFormDistribution() {
            this.itemDistribution.DepLine = 0;
            this.itemDistribution = {
                DepLine: 0,
                DepPorcentaje: 100,
                ObjPtd_GF: 0,
                ObjPrj_Ceco: 0,
                ObjPrj: 0
            }
        },

        dialogDetailEditable(item) {
            this.itemsDistribution = item.Details == null ? [] : item.Details
            this.dialogDetail = true
        },

        rowClickDistribution: function (item, row) {
            this.selectedDistribution = [item];
        },

        openDetail() {
            //Si es Compra
            if (this.Type == 1) {
                if (this.article == null) {
                    this.$fun.alert("Seleccione Articulo", "warning")
                    return
                }
            }
            /*  else {
                            if (this.item.DerDescription == null || this.item.DerDescription.length == 0) {
                                this.$fun.alert("Ingrese Descripcion", "warning")
                                return
                            }
                        } */

            /* if(this.selected.length <= 0){
                this.$fun.alert("Seleccione Items para Distribucion", "warning")
                    return
            } */
            this.clearFormDistribution();
            this.dialogDetail = true
        },

        addDistribution() {

            if (this.itemDistribution.ObjPtd_GF == 0 || this.itemDistribution.ObjPtd_GF == null) {
                this.$fun.alert("Seleccione [ObjPtd_GF]", "warning")
                return
            }
            if (this.itemDistribution.ObjPrj_Ceco == 0 || this.itemDistribution.ObjPrj_Ceco == null) {
                this.$fun.alert("Seleccione [ObjPrj_Ceco]", "warning")
                return
            }
            if (this.itemDistribution.DepPorcentaje == 0 || this.itemDistribution.DepPorcentaje == "") {
                this.$refs.DepPorcentaje.error("Ingrese Porcentaje")
                return
            }
            if (this.itemDistribution.DepPorcentaje > 100) {
                this.$refs.DepPorcentaje.error("Porcentaje no puede superar el 100%")
                return
            }
            if (this.TypeP == 1) {
                if (this.itemDistribution.ObjPrj == 0 || this.itemDistribution.ObjPrj == null) {
                    this.$fun.alert("Seleccione [Proyecto]", "warning")
                    return
                }
                if (this.itemDistribution.account == null) {
                    this.$fun.alert("Seleccione Cuenta Contable", "warning")
                    return
                }
                console.log("this.itemDistribution.account",this.itemDistribution.account);
                /*  if (
                     (this.itemsDistribution.find((x) => x.Ptd_GF == this.itemDistribution.ObjPtd_GF.SptID) &&
                         this.itemsDistribution.find((x) => x.Prj_Ceco == this.itemDistribution.ObjPrj_Ceco.CceCenterCoste))
                 ) {
                     this.$fun.alert("[ObjPtd_GF] y [ObjPrj_Ceco] ya fueron agregadas", "warning");
                     return;
                 } */
            }

            /* if (this.TypeP == 2) {
                if (
                    (this.itemsDistribution.find((x) => x.Ptd_GF == this.itemDistribution.ObjPtd_GF.PtdID) &&
                        this.itemsDistribution.find((x) => x.Prj_Ceco == this.itemDistribution.ObjPrj_Ceco.PrjID))
                ) {
                    this.$fun.alert("[ObjPtd_GF] y [ObjPrj_Ceco] ya fueron agregadas", "warning");
                    return;
                }
            } */

            var sumPorc = 0.0
            if (this.itemDistribution.DepLine == 0) {
                sumPorc = parseFloat(sumPorc) + parseFloat(this.itemDistribution.DepPorcentaje);
                this.itemsDistribution.forEach(element => {
                    sumPorc = parseFloat(sumPorc) + parseFloat(element.DepPorcentaje)
                });
                console.log("sumPorc", sumPorc.toFixed(3));
                if (sumPorc.toFixed(3) > 100) {
                    this.$refs.DepPorcentaje.error("Porcentaje no puede superar el 100%")
                    return
                }
                let item = {
                    DepPorcentaje: this.itemDistribution.DepPorcentaje,
                    TypeBusiness: this.typeBusinessDistribution,
                    TypeBusinessName: this.objBussiness.DedDescription,
                    TypeP: this.TypeP
                };

                if (this.TypeP == 1) {
                    item.Ptd_GF_Name = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.SptDescriptionLong
                    item.Ptd_GF = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.SptID
                    item.Prj_Ceco = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.CceCenterCoste
                    item.Prj_Ceco_Name = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.CceDescription
                    item.PrjID = this.itemDistribution.ObjPrj == null ? "" : this.itemDistribution.ObjPrj.PrjID
                    item.PrjName = this.itemDistribution.ObjPrj == null ? "" : this.itemDistribution.ObjPrj.PrjDescriptionLong
                    item.AreParentID = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.AreParentID
                    item.DepAccount = this.itemDistribution.account == null ? "" : this.itemDistribution.account
                }

                if (this.TypeP == 2) {
                    item.Prj_Ceco = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.PrjID
                    item.Prj_Ceco_Name = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.PrjDescriptionLong

                    item.Ptd_GF_Name = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.PtdDescriptionLong
                    item.Ptd_GF = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.PtdID
                }
                this.itemsDistribution.push(item);
                this.selectedDistribution = [item];
                this.clearFormDistribution()
                this.itemDistribution.DepPorcentaje = 100 - sumPorc
                this.itemDistribution.DepPorcentaje = this.itemDistribution.DepPorcentaje > 0 ? this.itemDistribution.DepPorcentaje.toFixed(2) : 0
            }
            for (let i = 0; i < this.itemsDistribution.length; i++) this.itemsDistribution[i].DepLine = i + 1;
        },

        removeDistribution() {
            if (this.itemsDistribution.length > 0) {
                this.itemsDistribution = this.itemsDistribution.filter((x) => x != this.selectedDistribution[0]);
                this.selectedDistribution = this.itemsDistribution.length > 0 ? [this.itemsDistribution[0]] : [];
                this.clearFormDistribution()
            }
        },

        downloadFile() {
            console.log(this.requirement.ExistContractFinal);
            if (this.requirement.ExistContractFinal == null) return;
            this.processing = true
            _sHelper.download(this.requirement.ExistContractFinal, 10).then((r) => {
                    this.processing = false
                    this.$fun.download(r.data, this.requirement.ExistContractFinal);
                },
                (e) => {
                    this.errors = e.response
                    this.processing = true;
                })
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
