import Service from "../Service";
import Vue from "vue";

const resource = "GenWorker/";

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },
    update(obj, requestID) {
        return Service.put(resource + "update", obj, {
            params: {},
        });
    },
    delete(obj, requestID) {
        return Service.delete(resource + "delete", {
            data: obj
        });
    },

    updateSignature(obj) {
        return Service.put(resource + "signature", obj, {
            params: {},
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    getUrlSearchWorker() {
        /* return Service.post(resource + "userlogistics", {}, {
            params: {},
        }); */
        return Vue.prototype.$http.defaults.baseURL + resource +"userlogistics";
    },

    usuarioslogistica() {        
        return Service.post(resource + "userlogistics", {});        
    },
};