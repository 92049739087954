import Service from "../Service";
import Vue from "vue";

const resource = "SecMail/";

export default {
    sendMail(obj) {
            return Service.post(resource + "sendmail", obj, {
                params: {},
            });
        },
};